
.ant-modal-confirm-btns .ant-btn-default {
  border-radius: 40px;
}

.ant-modal-root .ant-btn-primary {
  border-radius: 40px;
  background-color: #937e6b;
  border-color: #937e6b;
  color: #ffffff;
  margin-right: 12px;
}
.ant-modal-root .ant-btn-primary:hover,
.ant-modal-root .ant-btn-primary:focus {
  background-color: #937e6b;
  border-color: #937e6b;
  color: #ffffff;
  opacity: 0.7;
}

.theme-coke .ant-modal-root .ant-btn-primary, .theme-coke .ant-modal-root .ant-btn-default {
  background-color: #000000;
  border-color: #000000;
  color: #fff !important;
}
.theme-coke .ant-modal-root .ant-btn-primary:hover,
.theme-coke .ant-modal-root .ant-btn-primary:focus,
.theme-coke .ant-modal-root .ant-btn-default:focus,
.theme-coke .ant-modal-root .ant-btn-default:hover {
  background-color: #000000;
  border-color: #000000;
  color: #fff !important;
}
.theme-coke .ant-modal-root .ant-btn-default:disabled {
  opacity: 0.6;
}
/* .ant-modal-content .ant-btn-default {
  border-radius: 40px;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #000000;
  margin-right: 12px;
} */
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #964439;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #227b75;
}

.ant-typography a {
  color: #937e6b;
  font-weight: bold;
}

.ant-popover-inner {
  border-radius: 5px;
  padding: 9px;
}
.ant-popover-title {
  border: none;
}
.no-buttons .ant-modal-confirm-btns {
  display: none;
}
.ant-modal-content {
  border-radius: 6px;
}
.title-lg .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 23px;
}

.bordered-title .ant-modal-confirm-title {
  border-bottom: 1px solid #acacac;
  display: flex;
  min-height: 66px;
  align-items: center;
  padding: 0 20px;
}
.bordered-title.ant-modal-confirm .ant-modal-body {
  padding: 0;
}
.bordered-title.ant-modal-confirm .ant-modal-confirm-content {
  padding: 10px 20px;
}
.ant-scrolling-effect {
  overflow-y: scroll !important;
}

.switch-modal .ant-btn-primary {
  background-color:#964439;
}
.switch-modal .ant-btn-primary:focus {
  background-color:#964439;
}
.switch-modal .anticon-warning {
  color:#964439 !important;
}


.preview .anticon-info-circle {
  display: none;
}

.preview .ant-modal-confirm-content {
  margin-left: 0 !important;
}

.preview {
  width: 650px !important;
}